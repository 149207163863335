<template>
  <div class="multilevel" v-if="tableData.length > 0">
    <el-table :data="tableData" :height="tableHeight + 26" style="width: 100%">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业" width="180"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="100"></el-table-column>
      <el-table-column prop="licensePlateColor" label="车牌颜色" width="100">
        <template slot-scope="scope">{{
          colorObj[Number(scope.row.licensePlateColor)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
        width="150"
      >
        <template slot-scope="scope">{{ typeObj[Number(scope.row.type)] }}</template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableData[0].list"
        :key="index"
        :label="item.dataDate"
      >
        <el-table-column label="总里程" width="110">
          <template slot-scope="scope">{{
            scope.row.list[index].positionRunMile
          }}</template>
        </el-table-column>
        <el-table-column label="完整轨迹里程" width="110">
          <template slot-scope="scope">{{ scope.row.list[index].runMile }}</template>
        </el-table-column>
        <el-table-column label="缺失轨迹里程" width="110">
          <template slot-scope="scope">{{ scope.row.list[index].dvalueMile }}</template>
        </el-table-column>
        <el-table-column label="轨迹完整率" width="110">
          <template slot-scope="scope">{{
            scope.row.list[index].completeRate + "%"
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="在线率" width="110">
          <template slot-scope="scope">{{
            scope.row.list[index].onlineRate + "%"
          }}</template>
        </el-table-column> -->
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict } from "@/common/utils/index";
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },
  watch: {
    height(val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      colorObj: {},
      typeObj: {},
      tableHeight: 0,
    };
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset() {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "CPYS,VETY" }).then((res) => {
        if (res.code === 1000) {
          this.colorObj = formatDict(res.data.CPYS);
          this.typeObj = formatDict(res.data.VETY);
        }
      });
    },
  },
  created() {
    this.getDicts();
  },
};
</script>

<style></style>
